import * as React from 'react';
import Box from '@mui/material/Box';

const Grid = ({ sx, ...props }) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: {
        xs: '1fr 1fr',
        md: 'repeat(12, 1fr)',
      },
      gap: '1.5rem',
      ...sx,
    }}
    {...props}
  />
);

Grid.Col = ({ xs, sm, md, lg, xl, sx, ...props }) => (
  <Box
    sx={{
      gridColumn: {
        xs,
        sm,
        md,
        lg,
        xl,
      },
      ...sx,
    }}
    {...props}
  ></Box>
);

export default Grid;
