import React, { useState, useEffect } from 'react';

import Modal from '../Modal';
import { isBrowser } from '../../utils/isBrowser';
import { getVideoUrl } from '../../utils/functions';
import DownloadIcon from '../../images/icon-download.svg';
import ArrowLeftIcon from '../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../images/icon-arrow-right-short.svg';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const VideoModal = ({ open, onClose, videos, activeVideoIndex, setActiveVideoIndex }) => {
  const [iframeContent, setIframeContent] = useState(null);

  // This is to prevent flickering of the iframe when the carousel is changing slides
  useEffect(() => {
    setIframeContent(
      <iframe
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src={getVideoUrl(videos[activeVideoIndex].videoUrl)}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    );
  }, [activeVideoIndex, videos]);

  return (
    <Modal open={open} onClose={onClose} sx={{ maxWidth: '900px' }}>
      <div className="lightbox">
        <div className="lightbox-container">
          <OwlCarousel
            className="carousel-stage owl-carousel la-rounded-nav video-carousel"
            responsiveClass
            navigation="true"
            dots={false}
            navText={[
              `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
              `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
            ]}
            items={1}
            startPosition={activeVideoIndex}
            onTranslated={(e) => {
              setActiveVideoIndex(e.item.index);
            }}
            responsive={{
              0: {
                items: 1,
                nav: true,
              },
            }}
          >
            {videos.map(({ videoUrl }, index) => (
              <div className="carousel-item carousel-video media ratio_16-9" key={index}>
                <div style={{ position: 'relative', paddingBottom: '56.25%' }}>
                  {index === activeVideoIndex && <MemoizedIFrame src={getVideoUrl(videoUrl)} />}
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>

        <a
          href={videos[activeVideoIndex].downloadLink}
          download
          className="btn btn-md btn-primary-ii w-full download-btn"
          style={{
            maxWidth: '370px',
          }}
        >
          Download <img src={DownloadIcon} alt="Download icon" />
        </a>
      </div>
    </Modal>
  );
};

const MemoizedIFrame = React.memo(
  ({ src }) => {
    return (
      <iframe
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src={src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    );
  },
  (prevProps, nextProps) => prevProps.src === nextProps.src
);

export default VideoModal;
