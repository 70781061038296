import React from 'react';
import { RichText } from 'prismic-reactjs';

const OurStory = ({ title, description }) => {
  return (
    <div className="our-story">
      <h2>{title}</h2>

      <div
        style={{
          marginTop: '32px',
        }}
      >
        <RichText render={description.raw} />
      </div>
    </div>
  );
};

export default OurStory;
