import React, { useState } from 'react';

import Carousel from './Carousel';
import Lightbox from './Lightbox';
import MediaContainer from './MediaContainer';

const Flyers = ({ downloadAllLink, flyers }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [activeLightboxIndex, setActiveLightboxIndex] = useState(0);

  return (
    <MediaContainer title="Flyers" downloadAllLink={downloadAllLink}>
      <Carousel>
        {flyers.map((flyer, index) => (
          <div key={index}>
            <div
              style={{
                width: '100%',
                aspectRatio: '180/270',
              }}
              onClick={() => {
                setActiveLightboxIndex(index);
                setLightboxOpen(true);
              }}
            >
              <img src={flyer.image.fluid.src} alt={flyer.image.alt} className="media-img" />
            </div>
            <div className="caption">{flyer.caption}</div>
          </div>
        ))}
      </Carousel>
      <Lightbox
        assets={flyers}
        open={lightboxOpen}
        activeImageIndex={activeLightboxIndex}
        setActiveImageIndex={setActiveLightboxIndex}
        onClose={() => setLightboxOpen(false)}
      />
    </MediaContainer>
  );
};

export default Flyers;
