import React from 'react';

import { isBrowser } from '../../utils/isBrowser';
import ArrowLeftIcon from '../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../images/icon-arrow-right-short.svg';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const Carousel = ({ children, responsive }) => {
  return (
    <OwlCarousel
      className="carousel-stage owl-carousel la-rounded-nav mediakit-carousel"
      responsiveClass
      navigation={true}
      dots={false}
      navText={[
        `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
        `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
      ]}
      margin={16}
      loop={false}
      rewindNav={false}
      responsive={{
        0: {
          items: 2,
          nav: true,
        },
        768: {
          items: 3,
          nav: true,
        },
        1200: {
          items: 6,
          nav: true,
        },
        ...responsive,
      }}
    >
      {children}
    </OwlCarousel>
  );
};

export default Carousel;
