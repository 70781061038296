import React from 'react';

const Jumbotron = ({ jumbotronImage, title }) => {
  return (
    <div
      className="mediakit-jumbotron"
      style={{
        backgroundImage: `url(${jumbotronImage.url})`,
      }}
    >
      <div className="mediakit-jumbotron-overlay"></div>
      <h1>{title}</h1>
    </div>
  );
};

export default Jumbotron;
