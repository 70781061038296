import React, { useState } from 'react';

import Carousel from './Carousel';
import VideoModal from './VideoModal';
import MediaContainer from './MediaContainer';

const AdvertsAndTrailers = ({ downloadAllLink, assets, title }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [activeModalIndex, setActiveModalIndex] = useState(0);

  return (
    <MediaContainer title={title} downloadAllLink={downloadAllLink}>
      <Carousel
        responsive={{
          1200: {
            items: 5,
            nav: true,
          },
        }}
      >
        {assets.map((asset, index) => (
          <div key={index}>
            <div
              style={{
                width: '100%',
                aspectRatio: '217/314',
                position: 'relative',
              }}
              onClick={() => {
                setActiveModalIndex(index);
                setShowVideoModal(true);
              }}
            >
              <div className="trailer-meta">
                <span className="video-txt">Video</span>
                <span className="duration">{asset.duration}</span>
              </div>
              <img src={asset.thumbnailUrl} alt={asset.title} className="media-img" />
            </div>
            <div className="caption">{asset.title}</div>
          </div>
        ))}
      </Carousel>
      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        videos={assets}
        activeVideoIndex={activeModalIndex}
        setActiveVideoIndex={setActiveModalIndex}
      />
    </MediaContainer>
  );
};

export default AdvertsAndTrailers;
