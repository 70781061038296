import React from 'react';
import { graphql, navigate } from 'gatsby';
import BodyClassName from 'react-body-classname';

import Grid from '../components/Grid';
import { formatTitle } from '../utils/shop';
import { getSliceNumber } from '../utils/podCast';
import Flyers from '../components/Mediakit/Flyers';
import OurStory from '../components/Mediakit/OurStory';
import Jumbotron from '../components/Mediakit/Jumbotron';
import BusinessCards from '../components/Mediakit/BusinessCards';
import StayConnected from '../components/Mediakit/StayConnected';
import RelatedContent from '../components/podcast/RelatedContent';
import MentionedPlaces from '../components/Mediakit/MentionedPlaces';
import AssetsAndTrailers from '../components/Mediakit/AdvertsAndTrailers';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

const Mediakit = ({ data }) => {
  const {
    mediakit: { nodes: mediakitNodes },
    readData,
    totalWatchData,
    podCastData,
  } = data;

  const [mediakitData] = mediakitNodes;

  // redirect to 404 if no tour data
  if (!mediakitData) {
    if (typeof window !== 'undefined') {
      navigate('/404');
    }
    return null;
  }

  const jumbotronImage = mediakitData.data.jumbotronImage;
  const title = mediakitData.data.title.text;
  const ourStory = {
    title: mediakitData.data.ourStoryTitle.text,
    description: mediakitData.data.ourStoryContent,
  };

  const relatedListen = mediakitData.data.relatedListen
    ?.filter((content) => content?.podcast?.uid)
    .map((item) => {
      const pod = podCastData.nodes.find((pod) => pod.uid === item.podcast.uid);
      return {
        ...pod,
        category: 'LISTEN',
        url: `/podcast/${pod.uid}`,
      };
    });

  const relatedEpisodes = mediakitData.data.relatedEpisodes
    ?.filter((content) => content?.episode?.uid)
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.episode.uid);

      return {
        ...watch,
        category: 'WATCH',
        uid: item.episode.uid,
      };
    });

  const relatedRead = mediakitData.data.relatedRead
    ?.filter((content) => content?.read?.uid)
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item?.read?.uid);
      return {
        ...read.data,
        category: 'READ',
        url: read.url,
      };
    });

  const relatedShop = mediakitData.data.relatedProducts
    .filter((item) => !!item.product)
    .map((p) => {
      return {
        thumbnailUrl: p.product.image.src,
        category: 'SHOP',
        title: p.product.title,
        url: `/product/${formatTitle(p.product.title)}`,
      };
    });

  const relatedReadCount = relatedRead.length;
  const relatedEpisodesCount = relatedEpisodes.length;
  const relatedShopCount = relatedShop.length;
  const relatedListenCount = relatedListen.length;

  let relatedData = [];

  if (relatedEpisodes.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedEpisodes.slice(0, sliceNumber));
  }
  if (relatedRead.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedRead.slice(0, sliceNumber));
  }

  if (relatedShop.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedShop.slice(0, sliceNumber));
  }

  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(relatedListen.slice(0, sliceNumber));
  }

  const formatTrailerAndAdverts = (contentType) => {
    return contentType.map(({ youtubeLink, duration, downloadLink, thumbnail }) => {
      return {
        videoUrl: youtubeLink.url || youtubeLink.embed_url,
        title: youtubeLink.title,
        thumbnailUrl: thumbnail.fluid.src,
        duration,
        downloadLink: downloadLink.url,
      };
    });
  };

  const trailers = formatTrailerAndAdverts(mediakitData.data.trailers);
  const adverts = formatTrailerAndAdverts(mediakitData.data.adverts);
  const businessCards = mediakitData.data.business_cards;

  return (
    <BodyClassName className="body-dark mediakit">
      <DefaultLayoutComponent
        title={title}
        description="Lineage Journey can offer a unique and personalized experience for those interested in exploring their family history. The tours can provide an opportunity to visit the places where one's ancestors lived, worked, and perhaps even left a mark on history. One potential tour option could be a guided tour of the ancestral hometown or village. This tour could include visits to significant landmarks, such as the family home, local churches or cemeteries, and even historical sites or museums that relate to the family's history."
      >
        <div className="site-main">
          <Jumbotron jumbotronImage={jumbotronImage} title={title} />

          <div className="wrapper wrapper-xl pd-x-md">
            <OurStory title={ourStory.title} description={ourStory.description} />
          </div>

          <div className="wrapper wrapper-xl pd-x-md media-collection-wrapper">
            <Grid>
              <Grid.Col xs="1 / span 2" md="2 / span 10">
                <BusinessCards
                  businessCards={businessCards}
                  downloadAllLink={mediakitData.data.businessCardsDownloadAllUrl?.url}
                />

                <Flyers
                  flyers={mediakitData.data.flyers}
                  downloadAllLink={mediakitData.data.flyersDownloadAllUrl?.url}
                />

                <AssetsAndTrailers
                  title="Trailers"
                  assets={trailers}
                  downloadAllLink={mediakitData.data.trailersDownloadAllUrl?.url}
                />

                <AssetsAndTrailers
                  title="Adverts"
                  assets={adverts}
                  downloadAllLink={mediakitData.data.advertsDownloadAllUrl?.url}
                />
              </Grid.Col>
            </Grid>
          </div>

          <MentionedPlaces
            mentionedPlaces={mediakitData.data.mentionedInLogos}
            title={mediakitData.data.mentionedInTitle.text}
          />

          <div className="pd-x-md podcast wrapper wrapper-xl" style={{ marginTop: '40px' }}>
            <RelatedContent relatedContent={relatedData} />
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default Mediakit;

export const query = graphql`
  query MediakitPage {
    mediakit: allPrismicMediaKit {
      nodes {
        data {
          flyers {
            image {
              alt
              thumbnails
              url
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
            downloadLink: download_link {
              url
            }
          }
          flyersDownloadAllUrl: flyers_download_all_url {
            url
          }
          jumbotronImage: jumbotron_image {
            url
            thumbnails
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
          business_cards {
            image {
              alt
              url
              thumbnails
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
            caption
            downloadLink: download_link {
              url
            }
          }
          businessCardsDownloadAllUrl: business_cards_download_all_url {
            url
          }
          ourStoryContent: our_story_content {
            html
            raw
            text
          }
          ourStoryTitle: our_story_title {
            html
            raw
            text
          }
          mentionedInLogos: mentioned_in_logos {
            logo {
              url
              thumbnails
              alt
              fluid(maxWidth: 200) {
                src
              }
            }
          }
          mentionedInTitle: mentioned_in_title {
            html
            raw
            text
          }
          relatedEpisodes: related_episodes {
            episode {
              uid
              type
              slug
            }
          }
          relatedRead: related_reads {
            read {
              uid
              type
              slug
            }
          }
          relatedListen: related_listens {
            podcast {
              uid
              id
              type
            }
          }
          relatedProducts: related_products {
            product
          }
          stayConnectedText: stay_connected_text {
            text
            raw
            html
          }
          stayConnectedTitle: stay_connected_title {
            html
            raw
            text
          }
          title {
            html
            raw
            text
          }
          trailers {
            youtubeLink: youtube_link {
              url
              thumbnail_url
              thumbnail_height
              thumbnail_width
              title
              width
              video_id
              embed_url
              height
            }
            downloadLink: download_link {
              url
            }
            duration
            thumbnail {
              alt
              thumbnails
              url
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          trailersDownloadAllUrl: trailers_download_all_url {
            url
          }
          adverts {
            youtubeLink: advert_video_link {
              url
              thumbnail_url
              thumbnail_height
              thumbnail_width
              title
              width
              video_id
              embed_url
              height
            }
            downloadLink: download_link {
              url
            }
            duration
            thumbnail {
              alt
              thumbnails
              url
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          advertsDownloadAllUrl: adverts_download_all_url {
            url
          }
        }
      }
    }
    bgWatch: file(relativePath: { eq: "bg-watch.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          readDuration: read_duration1 {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
  }
`;
