import React from 'react';

const MediaContainer = ({ title, downloadAllLink, children }) => {
  return (
    <section className="media-container">
      <div className="media-container-header">
        <h2>{title}</h2>
        <div>
          <a href={downloadAllLink}>Download all</a>
        </div>
      </div>

      {children}
    </section>
  );
};

export default MediaContainer;
