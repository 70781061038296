import React from 'react';

const MentionedPlaces = ({ mentionedPlaces, title }) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
      }}
    >
      <div className="wrapper wrapper-xl pd-x-md mentioned-places">
        <h2>{title}</h2>

        <div className="logos-container">
          {mentionedPlaces.map(({ logo }) => (
            <div className="logo">
              <img src={logo.fluid.src} alt={logo.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MentionedPlaces;
