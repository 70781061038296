import React, { useState } from 'react';

import Carousel from './Carousel';
import Lightbox from './Lightbox';
import MediaContainer from './MediaContainer';

const BusinessCards = ({ downloadAllLink, businessCards = [] }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [activeLightboxIndex, setActiveLightboxIndex] = useState(0);

  if (businessCards.length === 0) {
    return null;
  }

  return (
    <MediaContainer title="Business Cards" downloadAllLink={downloadAllLink}>
      <Carousel
        responsive={{
          1200: {
            items: 5,
            nav: true,
          },
        }}
      >
        {businessCards.map((businessCard, index) => (
          <div key={index}>
            <div
              style={{
                width: '100%',
                aspectRatio: '1/1',
              }}
              onClick={() => {
                setActiveLightboxIndex(index);
                setLightboxOpen(true);
              }}
            >
              <img src={businessCard.image.fluid.src} alt={businessCard.image.alt} className="media-img" />
            </div>
            <div className="caption">{businessCard.caption}</div>
          </div>
        ))}
      </Carousel>
      <Lightbox
        assets={businessCards}
        open={lightboxOpen}
        activeImageIndex={activeLightboxIndex}
        setActiveImageIndex={setActiveLightboxIndex}
        onClose={() => setLightboxOpen(false)}
      />
    </MediaContainer>
  );
};

export default BusinessCards;
