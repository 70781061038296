import React from 'react';

import Modal from '../Modal';
import { isBrowser } from '../../utils/isBrowser';
import DownloadIcon from '../../images/icon-download.svg';
import ArrowLeftIcon from '../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../images/icon-arrow-right-short.svg';
import { removeCompressionParams } from '../../utils/removePrismicCompressionParams';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const Lightbox = ({ open, onClose, assets, activeImageIndex, setActiveImageIndex }) => {
  return (
    <Modal open={open} onClose={onClose} sx={{ maxWidth: '600px' }}>
      <div className="lightbox">
        <div className="lightbox-container">
          <OwlCarousel
            className="carousel-stage owl-carousel la-rounded-nav"
            responsiveClass
            navigation={true}
            dots={false}
            navText={[
              `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
              `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
            ]}
            items={1}
            startPosition={activeImageIndex}
            onTranslated={(e) => {
              setActiveImageIndex(e.item.index);
            }}
            responsive={{
              0: {
                items: 1,
                nav: true,
              },
            }}
          >
            {assets.map(({ image }, index) => (
              <div className="lightbox-image" key={index}>
                <img
                  src={image.fluid.src}
                  alt={image.alt}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>

        <a
          href={
            assets[activeImageIndex].downloadLink.url || removeCompressionParams(assets[activeImageIndex].image.url)
          }
          download
          target="_blank"
          className="btn btn-md btn-primary-ii w-full download-btn"
        >
          Download <img src={DownloadIcon} alt="Download icon" />
        </a>
      </div>
    </Modal>
  );
};

export default Lightbox;
