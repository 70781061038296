/** @jsx jsx */
import React from 'react';
import { Box } from '@mui/material';
import { createPortal } from 'react-dom';
import { jsx, css } from '@emotion/react';
import { useEffect, useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const Modal = ({ onClose, open, children, className, sx }) => {
  const modalPortalRef = useRef(null);
  const modalRef = useRef(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    modalPortalRef.current = document.querySelector('#portal');
    setMounted(true);
  }, []);

  useOnClickOutside(modalRef, () => {
    if (open) {
      onClose();
    }
  });

  return mounted && modalPortalRef.current
    ? createPortal(
        <Box className={`modal ${open ? 'active' : ''}`}>
          <Box
            className={`modal-container ${className}`}
            sx={{
              maxWidth: '1390px',
              ...sx,
            }}
            ref={modalRef}
          >
            <button className="modal-close" aria-label="close" onClick={onClose}></button>
            {children}
          </Box>
        </Box>,
        modalPortalRef.current
      )
    : null;
};

export default Modal;
